import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { resizeObserverHandler } from './util/resizeObserverHandler.js';
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status';
import { dispatchEvent } from './util/eventsHelper.js';
/**
* `ai-carbon-hero`
* 
* 
* @customElement
* @polymer
*/
class AiCarbonHero extends resizeObserverHandler(PolymerElement) {
    static get is(){ return 'ai-carbon-hero'; }
    static get template() {
    return html`
        <style>
            :host {
                --_p-hue:var(--ai-carbon-hero-button-background-hue, var(--ai-primary-color-hue, 230));
                --_p-saturation:var(--ai-carbon-hero-button-background-saturation, var(--ai-primary-color-saturation, 15%));
                --_p-lightness:var(--ai-carbon-hero-button-background-lightness, var(--ai-primary-color-lightness, 25%));

                --_s-hue:var(--ai-carbon-hero-background-hue, var(--ai-secondary-color-hue, var(--_bgh,0)));
                --_s-lightness:var(--ai-carbon-hero-background-lightness, var(--ai-secondary-color-lightness, var(--_bgl, 100%)));
                --_s-saturation:var(--ai-carbon-hero-background-saturation, var(--ai-secondary-color-saturation, var(--_bgs, 100%)));
                
                --_pc-hue:var(--ai-carbon-hero-button-color-hue, var(--ai-primary-color-contrast-hue, 0));
                --_pc-saturation:var(--ai-carbon-hero-button-color-saturation, var(--ai-primary-color-contrast-saturation, 0%));
                --_pc-lightness:var(--ai-carbon-hero-button-color-lightness, var(--ai-primary-color-contrast-lightness, 100%));

                --_sc-hue:var(--ai-carbon-hero-color-hue, var(--ai-secondary-color-contrast-hue, 230));
                --_sc-saturation:var(--ai-carbon-hero-color-saturation, var(--ai-secondary-color-contrast-saturation, 0%));
                --_sc-lightness:var(--ai-carbon-hero-color-lightness, var(--ai-secondary-color-contrast-lightness, 13%));

                --_sc-hsl:hsl(var(--_sc-hue),var(--_sc-saturation),var(--_sc-lightness));

                position: relative;
                display: block;
                box-sizing: border-box;
                width: 100%;
                font-size:.6em;
                line-height: var(--ai-carbon-hero-line-height, 1.4);
                background-image:var(--ai-carbon-hero-background);
                background-position: center;
                background-size: cover;
                display:flex;
                flex-direction:column;
                align-items:center;
                justify-content: center;
                padding:13.5em .5em 6.5em .5em;
                color:var(--_sc-hsl);
            }
            :host([sm]){
                font-size:.6em;
            }
            :host([med]){
                font-size:.8em;
            }
            :host([lrg]){
                font-size:1em;
            }
            div{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: var(--ai-carbon-hero-overlay-background,
                    linear-gradient(180deg, 
                        hsla(var(--_s-hue),var(--_s-saturation),var(--_s-lightness),1) 10%,
                        hsla(var(--_s-hue),var(--_s-saturation),var(--_s-lightness),.75)
                    )
                );
            }
            :host([no-gradient]) div{
                background:hsla(var(--_s-hue),var(--_s-saturation),var(--_s-lightness), .75);
            }
            h1{
                line-height: 1.2;
                font-size:1.8em;
                text-align: center;
                margin:0 0 1.1em 0;
                position: relative;
                z-index: 2;
            }
            a{
                display: inline-block;
                cursor: pointer;
                font-weight: var(--ai-carbon-hero-button-font-weight, 500);
                font-size: 1.1em;
                padding:1em 4em;
                letter-spacing:1px;
                color:hsl(var(--_pc-hue), var(--_pc-saturation), var(--_pc-lightness));
                background:hsl(var(--_p-hue), var(--_p-saturation), var(--_p-lightness));
                text-decoration: none;
                position: relative;
                z-index: 2;
                box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
                transition:box-shadow .3s;
            }
            a:hover{
                background:hsl(var(--_p-hue), calc(var(--_p-saturation) + 5%), calc(var(--_p-lightness) + 10%));
                box-shadow: 0 12px 16px 1px rgba(0, 0, 0, 0.14), 0 4px 22px 3px rgba(0, 0, 0, 0.12), 0 6px 7px -4px rgba(0, 0, 0, 0.4);
            }   
            ::slotted(*){
                position: relative;
                z-index: 2;
            }
            ::slotted([slot='button']){
                cursor: pointer;
                transition: box-shadow .3s;
                box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
            }
            ::slotted([slot='button']:hover){
                box-shadow: 0 12px 16px 1px rgba(0, 0, 0, 0.14), 0 4px 22px 3px rgba(0, 0, 0, 0.12), 0 6px 7px -4px rgba(0, 0, 0, 0.4);
            }
                     
            </style>
            <div></div>
            <h1><slot>YOUR PREMIER<br />SELF STORAGE FACILITY</slot></h1>
            <slot name='button'><a href$='[[href]]'>VIEW UNIT RATES</a></slot>
            `;
        }
        static get properties() {
            return {
                version : {
                    type : String,
                    value : '1.0.0',
                    readonly : true
                },
                prop1 : {
                    type : String,
                    value : 'ai-carbon-hero',
                },
                breakpoints : {
                    type : Object,
                    value : {
                        lrg : 1000,
                        med : 700,
                        sm : 500
                    }
                },
                noGradient : {
                    type : Boolean,
                    value : false,
                    reflectToAttribute : true
                },
                href : {
                    type : String,
                    value : '#'
                },
                backgroundImage : {
                    type : String,
                    value : null,
                    observer : '_backgroundImageObserver'
                },
                loading : {
                    type : Boolean,
                    value : true,
                    reflectToAttribute : true
                }
            };
        }
    constructor(){
        super();
        this.removeAttribute('unresolved');
    }

    ready(){
        super.ready();
        if(! this.backgroundImage){
            this.loading = false;
        }
        const buttonSlot = this.shadowRoot.querySelector("slot[name='button']");
        const assigned = buttonSlot.assignedNodes();
        if(assigned.length > 0){
            if(! assigned.some(node => node.href))
                console.warn('ai-carbon-hero slotted elements missing href attribute!');
        }else if(!this.href || this.href == '#'){
            console.warn("ai-carbon-hero missing href attribute!");
        }
    }

    _loadImg(){
        const img = new Image();
        this.loading = true;
        img.addEventListener('load', evt => {
            this.style.backgroundImage = `url(${this.backgroundImage})`;
            this.loading = false;
            dispatchEvent('load', this);
        });
        img.src = this.backgroundImage;
    }

    _backgroundImageObserver(newVal, oldVal){
        if(this.backgroundImage){
            afterNextRender(this, this._loadImg);
        }
    }
}

window.customElements.define(AiCarbonHero.is, AiCarbonHero);
